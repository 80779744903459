import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Columns } from "react-bulma-components"

import Layout from "../../components/Layout"
import NewsItem from "../../components/NewsItem"


const News = () => {
    const data = useStaticQuery(graphql`
        query NewsQuery {
            allMarkdownRemark(
                filter: {
                    fields: {
                        sourceName: {eq: "news"}
                    }
                }
                sort: {
                    fields: [frontmatter___date]
                    order: DESC
                }
            ) 
            {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            date(formatString: "DD MMMM YYYY"
                                 locale: "ru-RU")
                        }
                        html
                        fields {
                            sourceName
                        }
                    }
                }
            }
        }
    `)
    const news = data.allMarkdownRemark.edges
    const description = 
        "На этой странице Вы найдете последние новости нашей компании"
    return (
        <Layout 
            title="Новости" 
            subtitle={description}
            description={description}
            slug="/main/news/"
        >
            {news.map(({ node }) => (
                <Columns key={node.id}>
                    <Columns.Column>
                        <NewsItem 
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                        >
                            <div dangerouslySetInnerHTML={{ __html: node.html }} />
                        </NewsItem>
                    </Columns.Column>
                </Columns>
            ))}
        </Layout>
    )
}

export default News